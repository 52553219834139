import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { ArticleType, mergeArticles } from "utils/merge-articles"
import { getArticleCreationDate } from "utils/utils"
import NewsArticlePreviewComponent, { NewsType } from "./preview"

const NewsOverviewMergedComponent = () => {
  const articles = useStaticQuery(graphql`
    {
      allStrapiArticle(sort: { order: ASC, fields: createdAt }) {
        # allStrapiArticle(limit: 10, sort: { order: ASC, fields: createdAt }) {
        edges {
          node {
            id
            slug
            createdAt
            content
            title
            imported {
              created
            }
          }
        }
      }
      faustball {
        mtvArticles {
          id
          date
          slug
          link
          title
          excerpt
          content
        }
      }
    }
  `)

  const merge = mergeArticles(
    articles.allStrapiArticle.edges,
    articles.faustball.mtvArticles,
    // articles.allMtvArticle.edges,
    0,
    10
  )

  return (
    <>
      <div className="mt-6 grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
        {merge.map(article => {
          return (
            <div key={article.id}>
              {getNewsArticlePreviewComponent(article)}
            </div>
          )
        })}
      </div>
    </>
  )
}

export const getNewsArticlePreviewComponent = article => {
  switch (article.type) {
    case ArticleType.MTV:
      return (
        <NewsArticlePreviewComponent
          key={article.id}
          id={article.id}
          newsType={NewsType.MTV}
          slug={article.slug}
          created={article.date}
          content={article.content}
          title={article.title}
          contentLength={200}
          mtvImported={new URL(article.link)}
        />
      )

    default:
      return (
        <NewsArticlePreviewComponent
          key={article.id}
          id={article.id}
          newsType={NewsType.Faustball}
          slug={article.slug}
          created={getArticleCreationDate(article)}
          content={article.content}
          title={article.title}
          contentLength={200}
        />
      )
  }
}

export default NewsOverviewMergedComponent
