import { CalendarIcon } from "@heroicons/react/24/solid"
import { Link } from "gatsby"
import moment from "moment"
import * as React from "react"
import { FC } from "react"
import MTVImportedBadge from "./mtv-imported-badge"
import striptags from "striptags"
import Markdown from "react-markdown"

export enum NewsType {
  Faustball = 1,
  MTV,
}

interface ArticlePreview {
  id: string
  newsType: NewsType
  slug: string
  title: string
  content: string
  created: Date
  contentLength: number
  mtvImported?: URL
}

const NewsArticlePreviewComponent: FC<ArticlePreview> = ({
  newsType = NewsType.Faustball,
  slug = "",
  content = "",
  created = new Date(0, 0, 0, 0, 0),
  title = "",
  contentLength = 200,
  mtvImported = undefined,
}) => {
  const getLink = (): string => {
    if (newsType == NewsType.MTV) {
      return `/news/mtv/${slug}`
    }

    return `/news/${slug}`
  }

  const getDate = (): string => {
    return moment(created).format("DD.MM.YYYY HH:mm")
  }

  const stripHTML = (html: string): string => {
    var stripped = striptags(html)
    stripped = stripped.replace(/&nbsp;/g, " ")

    return stripped
  }

  const shortendContent = (): string => {
    var maxLength = contentLength
    var strippedContent = stripHTML(content)
    var trimmedString = strippedContent.substring(0, maxLength)
    trimmedString = trimmedString.substring(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    )

    return `${trimmedString} …`
  }
  return (
    <div>
      {mtvImported !== undefined && (
        <div className="float-right">
          <MTVImportedBadge url={mtvImported} />
        </div>
      )}
      <div className="mb-1 flex items-center text-gray-500">
        <CalendarIcon
          className="mr-1.5  h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
        <p className="text-sm">{getDate()}</p>
      </div>
      <Link
        to={getLink()}
        className="text-xl font-semibold text-primary-light hover:text-primary dark:text-gray-300 dark:hover:text-gray-400"
      >
        {title}
      </Link>
      <Markdown className="mt-3 text-base text-gray-500">
        {shortendContent()}
      </Markdown>
      <div className="mt-3">
        <Link
          to={getLink()}
          className="text-base font-semibold text-gray-800 hover:text-gray-500 dark:text-primary-lighter dark:hover:text-primary-light"
        >
          Lesen
        </Link>
      </div>
    </div>
  )
}

export default NewsArticlePreviewComponent
