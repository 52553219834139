import { getArticleCreationDate } from "./utils"

export const mergeArticles = (
  ownArticles: any[],
  mtvArticles: any[],
  skip?: number,
  limit?: number
): any[] => {
  if (skip === undefined || skip < 0) {
    skip = 0
  }

  if (limit === undefined || limit <= 0) {
    limit = 10
  }
  limit += skip

  var merged = [...ownArticles, ...mtvArticles]
  merged = merged
    .map(article => {
      if (article.node !== undefined) {
        return article.node
      }
      return article
    })
    .map(article => ({ ...article, type: getArticleType(article) }))
    .sort((a, b) => {
      const dateA = new Date(getDateForArticle(a))
      const dateB = new Date(getDateForArticle(b))
      if (dateA < dateB) return 1
      if (dateA > dateB) return -1
      if (dateA === dateB) return 0
    })
    .slice(skip, limit)
  return merged
}

export enum ArticleType {
  Own = 1,
  MTV,
}

export const getArticleType = (article: any): ArticleType => {
  if (article.link !== undefined && article.link.includes("mtv-vorsfelde.de")) {
    return ArticleType.MTV
  }

  return ArticleType.Own
}

const getDateForArticle = article => {
  switch (article.type) {
    case ArticleType.MTV:
      return article.date
    default:
      return getArticleCreationDate(article)
  }
}
