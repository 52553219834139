import React from "react"
import { Link } from "gatsby"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <nav style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        {previousPagePath && (
          <Link className="readStoryLink" to={previousPagePath}>
            <button>← Neuere Artikel</button>
          </Link>
        )}
      </div>

      <div style={{ justifySelf: "flex-end" }}>
        {nextPagePath && (
          <Link className="readStoryLink" to={nextPagePath}>
            <button>Ältere Artikel →</button>
          </Link>
        )}
      </div>
    </nav>
  )
}

export default Pager
