export const getArticleCreationDate = (article): Date => {
  if (
    article.imported !== undefined &&
    article.imported !== null &&
    article.imported.created !== undefined
  ) {
    return new Date(article.imported.created)
  }
  return new Date(article.createdAt)
}
