import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Pager from "components/pager"
import Headline from "components/general/headline"
import { mergeArticles } from "utils/merge-articles"
import { getNewsArticlePreviewComponent } from "components/news/overview"

export const ArticleArchive = ({ data, pageContext }) => {
  const title = "Neuigkeiten"
  const description = "Aktuelles von der Faustball-Mannschaft des MTV Vorsfelde"
  const seo = {
    metaTitle: title,
    metaDescription: description,
  }

  const merge = mergeArticles(
    data.allStrapiArticle.edges,
    data.faustball.mtvArticles,
    pageContext.skip,
    pageContext.limit
  )

  return (
    <Layout seo={seo}>
      <div className="main-box">
        <Headline headline={title} subHeadline={description} />

        <div className="mt-6 grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          {merge.map(article => {
            return (
              <div key={article.id}>
                {getNewsArticlePreviewComponent(article)}
              </div>
            )
          })}
        </div>

        <div className="-mb-10 mt-16 border-t border-gray-200 bg-gray-50 px-8 py-10 dark:border-gray-900 dark:bg-gray-800">
          <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
            <div className="max-w-4xl">
              <Pager pageContext={pageContext} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ArticleArchive

export const pageQuery = graphql`
  {
    allStrapiArticle(sort: { order: ASC, fields: createdAt }) {
      edges {
        node {
          id
          slug
          createdAt
          content
          title
          imported {
            created
          }
        }
      }
    }
    faustball {
      mtvArticles {
        id
        date
        slug
        link
        title
        excerpt
        content
      }
    }
  }
`
